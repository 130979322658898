/* eslint-disable no-unreachable */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { css } from 'styled-components'
import { NotificationContainer, NotificationManager } from 'react-notifications'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import {
  H2,
  H3,
  ParagraphLarge,
  ParagraphMedium,
  Subtitle,
} from '../components/typography'
import Button from '../components/Button'
import AddIcon from '../static/add.svg'

import ProductSize from './ProductSize'
import { Wrapper, ProductImage, RelatedProduct, QuantityInput } from './styles'
import { colors } from '../utils'

import 'react-notifications/lib/notifications.css'

export const query = graphql`
  query($id: Int!, $category: String!) {
    postgres {
      item(id: $id) {
        id
        imageUrlCompressed
        model
        name_en: nameEn
        name_ar: nameAr
        name_he: nameHe
        price
        description_ar: descriptionAr
        description_en: descriptionEn
        description_he: descriptionHe
        unit
        category
        properties
        size
      }
    }
    postgres {
      items(
        filter: { category: { equalTo: $category }, id: { notEqualTo: $id } }
        first: 3
      ) {
        nodes {
          price
          unit
          size
          properties
          nameHe
          nodeId
          nameEn
          nameAr
          model
          imageUrlCompressed
          id
          descriptionHe
          descriptionEn
          descriptionAr
          createdAt
          category
        }
      }
    }
  }
`

const ProductExample = ({
  data: {
    postgres: {
      item: {
        name_en: nameEn,
        name_ar: nameAr,
        name_he: nameHe,
        price,
        properties,
        description_ar: descriptionAr,
        description_en: descriptionEn,
        description_he: descriptionHe,
        imageUrlCompressed,
        size: sizes,
      },
      items: { nodes: allProducts },
    },
  },
}) => {
  const { locale } = useIntl()
  const [quantity, setQuantity] = useState(1)
  const [isAdded, setIsAdded] = useState(false)
  //  const description = getDescription();
  const handleSizeChange = value => {
    setSize(value)
  }

  const getNameDesc = () => {
    switch (locale) {
      case 'en':
        return [nameEn, descriptionEn]
      case 'he':
        return [nameHe, descriptionHe]
      case 'ar':
        return [nameAr, descriptionAr]
      default:
        return [nameEn, descriptionEn]
    }
  }

  const [name, description] = getNameDesc()

  const handleAddToCart = () => {
    setIsAdded(!isAdded)
    NotificationManager.success(
      'Added to cart go checkout now',
      'successfully added',
    )
  }

  const renderSizes = () => {
    if (sizes.length === 0 || sizes[0] === '') {
      return ''
    }

    if (sizes.length === 1) {
      return (
        <>
          <Subtitle
            css={css`
              margin: ${locale === 'en' ? '0 1rem 0 0' : '0 0 0 1rem'};
            `}
          >
            Size :
          </Subtitle>
          <span
            css={css`
              font-size: 1.6rem;
              font-weight: 700;
            `}
          >
            {sizes[0]}
          </span>
        </>
      )
    }

    return (
      <>
        <Subtitle
          css={css`
            margin: ${locale === 'en' ? '0 1rem 0 0' : '0 0 0 1rem'};
          `}
        >
          Size
        </Subtitle>
        {sizes.map(value => value && <ProductSize key={value} size={value} />)}
      </>
    )
  }

  return (
    <Layout>
      <SEO title={name} dir={locale === 'en' ? 'ltr' : 'rtl'} />
      <Wrapper>
        <div
          css={css`
            grid-area: main;
          `}
        >
          <H2>{name}</H2>
          <ParagraphLarge
            css={css`
              margin-top: 2rem;
              margin-bottom: 4rem;
            `}
          >
            {`$ ${price} USD`}
          </ParagraphLarge>
          <ParagraphMedium
            color="rgba(36, 36, 36, 0.8)"
            css={css`
              margin-bottom: 3rem;
              width: 100%;

              @media (min-width: 600px) {
                width: 60%;
              }
            `}
          >
            {description}
          </ParagraphMedium>
          <div
            css={css`
              margin-bottom: 6em;
            `}
          >
            {properties &&
              Object.keys(properties)
                .filter(key => key && properties[key])
                .map(key => (
                  <Subtitle
                    css={css`
                      margin-bottom: 2rem;
                    `}
                  >
                    <FormattedMessage id={key} /> :
                    <span
                      css={css`
                        font-weight: 700;
                        margin: ${locale === 'en'
                          ? '0 0 0 0.5rem'
                          : '0 0.5rem 0 0'};
                      `}
                    >
                      <FormattedMessage
                        defaultMessage={properties[key]}
                        id={properties[key]}
                      />
                    </span>
                  </Subtitle>
                ))}

            <Subtitle
              css={css`
                margin-bottom: 2rem;
              `}
            >
              <label htmlFor="quantity">
                <FormattedMessage id="quantity" />
              </label>
              <QuantityInput
                id="quantity"
                type="number"
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
                dir={locale === 'en' ? 'ltr' : 'rtl'}
              />
            </Subtitle>
            <div
              css={css`
                display: flex;
                align-items: center;
                margin-top: 3rem;
                div:not(:last-child) {
                  margin-right: 2rem;
                }

                @media (max-width: 600px) {
                  justify-content: center;
                  align-items: center;
                }
              `}
            >
              {renderSizes()}
            </div>
          </div>
          <div
            css={css`
              @media (max-width: 600px) {
                display: flex;
                justify-content: center;
                margin-bottom: 6rem;
              }
            `}
          >
            <Button primary shadow text="Add to cart" onClick={handleAddToCart}>
              <AddIcon />
            </Button>
          </div>
        </div>
        <ProductImage src={imageUrlCompressed} alt={nameEn} />
        <div
          css={css`
            grid-area: related;
          `}
        >
          <H3
            css={css`
              display: inline-block;
              border-bottom: 2px solid ${colors.primary};
              margin-bottom: 3rem;
            `}
          >
            Related Products
          </H3>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-evenly;
            grid-area: products;
            flex-direction: column;
            @media (min-width: 600px) {
              flex-direction: row;
            }
          `}
        >
          {allProducts.map(product => (
            <Link to={`/products/${product.model}`} key={product.id}>
              <RelatedProduct
                src={product.imageUrlCompressed}
                alt={product.nameEn}
              />
            </Link>
          ))}
        </div>
        <NotificationContainer />
      </Wrapper>
    </Layout>
  )
}

ProductExample.propTypes = {
  data: PropTypes.shape({
    postgres: PropTypes.shape({
      item: PropTypes.object.isRequired,
      items: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default ProductExample
