import styled from 'styled-components'

import { colors } from '../utils'

export const Wrapper = styled.div`
  max-width: 100%;
  padding: 9rem 10rem 20rem 10rem;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'main main photo'
    'main main related'
    'main main products';
  @media (max-width: 600px) {
    padding: 8rem 7rem 20rem 7rem;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      'photo'
      'main'
      'related'
      'products';
  }

  @media (min-width: 800px) {
    width: 85%;
    margin: 0 auto;
  }
`

export const ProductImage = styled.img`
  display: block;
  width: 42.7rem;
  height: 40rem;
  border: 4px solid #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 5rem;
  grid-area: photo;
  @media (max-width: 600px) {
    width: 100%;
  }

  @media (min-width: 1800px) {
    width: 60rem;
  }
`

export const RelatedProduct = styled.img`
  display: block;
  width: 12.5rem;
  height: 20vh;
  border: 4px solid #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  @media (max-width: 600px) {
    width: 80%;
    height: 28rem;
    margin: 3rem auto;
  }

  @media (min-width: 1800px) {
    width: 18rem;
  }
`

export const QuantityInput = styled.input`
  width: 6rem;
  padding: 0.5rem 0.5rem 0.5rem 0.8rem;
  margin: ${({ dir }) => (dir === 'ltr' ? '0 0 0 2rem' : '0 2rem 0 0')};
  border: 1px solid ${colors.gray2};
  border-radius: 5px;
  font-family: Roboto;
  font-size: 1.8rem;
  color: ${colors.black};
`
