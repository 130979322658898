import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Button as ButtonText } from '../typography'
import { colors } from '../../utils'

const StyledButton = styled.button`
  padding: 1.4rem 5rem;
  color: ${colors.white};
  background: ${({ primary }) => (primary ? colors.primary : colors.secondary)};
  box-shadow: ${({ shadow }) =>
    shadow
      ? `0px 4px 10px rgba(0, 0, 0, 0.25),
    0px -4px 10px rgba(0, 0, 0, 0.25)`
      : 'none'};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  svg {
    margin-left: 1rem;
  }
  :hover {
    background: ${colors.white};
    color: ${({ primary }) => (primary ? colors.primary : colors.secondary)};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25),
      0px -4px 10px rgba(0, 0, 0, 0.25);
    transform: translateY(-3px);
    path {
      fill: ${({ primary }) => (primary ? colors.primary : colors.secondary)};
    }
  }
  :active {
    transform: translateY(0);
  }
  @media (min-width: 800px) {
    padding: 2.5rem 5rem;
  }
`

const Button = ({ type, text, children, primary, shadow, onClick }) => (
  <StyledButton primary={primary} shadow={shadow} type={type} onClick={onClick}>
    <ButtonText>{text}</ButtonText>
    {children}
  </StyledButton>
)

Button.defaultProps = {
  onClick: () => null,
  primary: false,
  shadow: false,
  type: 'button',
}

Button.propTypes = {
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  shadow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default Button
