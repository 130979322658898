/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import { colors } from '../utils'

const ProductSize = ({ size }) => {
  const [isClicked, setIsClicked] = useState(false)
  return (
    <button
      type="button"
      onClick={() => setIsClicked(!isClicked)}
      css={css`
        background: ${isClicked ? colors.gray3 : colors.white};
        border: 0.5px solid rgba(36, 36, 36, 0.8);
        padding: 1rem 0.8rem;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        :hover {
          background: ${colors.gray3};
        }

        transition: all 0.2s ease-in-out;
        cursor: pointer;
        outline: none;
        font-size: 1.6rem;

        margin: 0 0 0 1rem;
      `}
    >
      {size}
    </button>
  )
}

ProductSize.propTypes = {
  size: PropTypes.number.isRequired,
}

export default ProductSize
